<template lang="pug">
  v-container#user-profile(fluid='' tag='section' style="padding: 0px;")
    .ticket
      table
        thead
          tr
            th CANT
            th PRODUCTO
            th $$
        tbody
          tr
            td 1.00
            td CHEETOS VERDES 80 G
            td $8.50
          tr
            td 2.00
            td KINDER DELICE
            td $10.00
          tr
            td 1.00
            td COCA COLA 600 ML
            td $10.00
          tr
            td
            td TOTAL
            td $28.50
      p ¡GRACIAS POR SU COMPRA!
</template>
<script>
  export default {
    props: {
      print: null,
    },
    methods: {
      printFac () {
        window.print()
      },
    },
    watch: {
      print () {
        if (this.print) {
          setTimeout(() => {
            this.printFac()
          }, 1000)
        }
      },
    },
  }
</script>
